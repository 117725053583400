@use '../../styles/vars';
.not-found {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: apparition 1s ease-out forwards;

  &__container {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin-bottom: 7em;

    &__img {
      height: 100%;
      border-radius: 50%;
    }
  }

  &__title {
    font-size: 10em;
    font-weight: bold;
    color: vars.$white;
  }

  &__text {
    margin-top: 5em;
    color: vars.$grey;
  }

  &__button {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .2s;

    &__icon {
      background-color: vars.$mainColor;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 1.3em;
      margin-top: 3em;
      z-index: 2;
      border: 2px solid vars.$mainColor;

      &__img {
        height: 100%;
      }
    }

    &__label {
      color: vars.$white;
      text-align: center;
      opacity: 0;
      width: 20em;
      transition: .3s;
      transform: translateY(-1em);
    }

    &:hover {
      .not-found__button__label {
        opacity: 1;
        transform: translateY(1em);
      }
      
      .not-found__button__icon {
        border-color: vars.$white;
      }
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

@media screen and (max-width: 850px) {
  .not-found {
    margin-bottom: 5em;

    &__container {
      margin-bottom: 2em;
    }

    &__title {
      font-size: 5em;
    }

    &__text {
      margin-top: 1em;
      font-size: 0.8em;
    }

    &__button {
      &__icon {
        width: 60px;
        height: 60px;
        padding: 1em;
      }

      &__label {
        opacity: 1;
        transform: none;
        margin-top: 1em;
        font-size: 0.8em;
      }

      &:hover {
        .not-found__button__label {
          transform: none;
        }
      }
    }
  }
}