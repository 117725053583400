@use '../../styles/vars';

@media screen and (min-width: 850px) {
  .cursor {
    pointer-events: none;
    overflow: hidden;
    position: fixed;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 999;

    &__element {
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: vars.$white;
      border: 1px solid vars.$black;
      z-index: 999;
    }
  }

  .pulse {
    position: absolute;
    border-radius: 50%;
    background-color: #e3e5ebbe;
    height: 10px;
    width: 10px;
    animation: pulsing .3s ease-out;
    border: none;
    z-index: 500;
  }

  @keyframes pulsing {
    100% {
      transform: scale(5);
      opacity: 0;
    }
  }
}