@use '../../styles/vars';

@media screen and (min-width: 850px) {
  ::-webkit-scrollbar {
    width: 12px;
    background: vars.$darkGrey;
    z-index: 100;
    background: vars.$black;
  }

  ::-webkit-scrollbar-track {
    background: vars.$darkGrey;
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
  }

  ::-webkit-scrollbar-thumb {
    background: vars.$mainColor;
    border-radius: 10px;
    z-index: 110;

    &:hover {
      background: rgb(100, 66, 148);
    }
  }
}