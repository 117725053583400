@use '../../styles/vars';

.home {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(90deg, rgba(10, 10, 10, 0.8),
  rgba(100, 100, 100, 0.8)), url(https://zupimages.net/up/21/33/sdiy.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  animation: apparition 1s ease-out forwards;

  &__header {
    margin-left: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-5em);
    transition: .3s;
    transition-delay: .5s;
    
    &.translate {
      transform: translateY(0);
    }

    &__title {
      opacity: 0;
      transform: translateX(-50px);
      animation: apparition 1s 0.5s ease-out forwards;

      &__label {
        color: vars.$white;
        text-shadow: 2px 2px 4px vars.$black;
        font-size: 3em;
        font-weight: bold;
        z-index: 2;
        transition: .3s;

        &:hover {
          transform: scale(0.9);
        }
      }
    }

    &__subtitle {
      transform: translateX(50px);
      opacity: 0;
      animation: apparition 1s 0.5s ease-out forwards;

      &__label {
        color: vars.$white;
        text-shadow: 2px 2px 4px vars.$black;
        font-size: 2em;
        font-weight: bold;
        margin: 1.5em;
        transition: .3s;
        z-index: 2;

        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }

  &__icon1 {
    position: fixed;
    top: -5vh;
    right: 5vw;
    z-index: 1;
    height: 70px;
    padding: 1em;
    animation: to_bottom 23s linear infinite;
  }

  &__icon2 {
    position: fixed;
    top: -10vh;
    right: 30vw;
    z-index: 1;
    height: 60px;
    padding: 1em;
    animation: to_bottom 27s linear infinite;
  }

  &__icon3 {
    position: fixed;
    top: -5vh;
    right: 55vw;
    z-index: 1;
    height: 65px;
    padding: 1em;
    animation: to_bottom 20s linear infinite;
  }

  &__icon4 {
    position: fixed;
    top: -15vh;
    right: 70vw;
    z-index: 1;
    height: 60px;
    padding: 1em;
    animation: to_bottom 30s linear infinite;
  }

  &__icon5 {
    position: fixed;
    top: -5vh;
    right: 85vw;
    z-index: 1;
    height: 55px;
    padding: 1em;
    animation: to_bottom 18s linear infinite;
  }
}

@media screen and (max-width: 1000px) {
  .home {
    &__header {
      &__title__label {
        font-size: 2em;
      }

      &__subtitle__label {
        font-size: 1em;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .home {
    &__header {
      transform: none;
      margin-top: 5em;
      margin-left: 0;

      &__title__label {
        font-size: 1.5em;
      }

      &__subtitle__label {
        font-size: 1em;
      }
    }

    &__icon1 {
      height: 45px;
    }

    &__icon2 {
      height: 45px;
    }

    &__icon3 {
      height: 45px;
    }

    &__icon4 {
      height: 45px;
    }

    &__icon5 {
      height: 45px;
    }
  }
}

@media screen and (max-width: 300px) {
  .home__header {
    &__title__label {
      font-size: 1em;
    }

    &__subtitle__label {
      font-size: 0.8em;
    }
  }
}

@keyframes to_bottom {
  100% {
    transform: translateY(120vh);
  }
}