@use '../../styles/vars';

.portfolio {
  padding-bottom: 8em;

  &__menu {
    margin-top: 1em;
    width: 100%;
    opacity: 0;
    transform: translateY(20px);
    animation: apparition 1s ease-out forwards;

    &__list {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      &__item {
        width: 90%;
        max-width: 300px;
        background-color: vars.$darkGrey;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin: 0.5em;
        transition: transform .2s;

        &__img {
          height: 70px;
          padding: 1em;
        }

        &__title {
          font-size: 0.8em;
          color: vars.$white;
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .portfolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10em;
    padding-bottom: 20em;

    &__menu {
      position: fixed;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 30em;
      height: 6em;
      padding: 2em;
      background: linear-gradient(rgba(vars.$black, 0) 0%, rgba(vars.$black, 1) 30%);
      z-index: 5;
      
      &__list {
        width: 30em;
        flex-direction: row;
        background: linear-gradient(rgba(vars.$black, 0) 0%, rgba(vars.$black, 1) 50%);

        &__item {
          width: 34%;
          flex-direction: column;
          justify-content: center;
          border: 2px solid vars.$grey;
          transition: width .3s ease;
          height: 6em;
          margin: 0 1em;
          transition: .3s;

          &:hover {
            width: 60%;
            border-color: vars.$mainColor;

            .portfolio__menu__list__item__img {
              transform: translateY(-1em);
            }

            .portfolio__menu__list__item__title {
              opacity: 1;
              transform: translateY(1.5em);
            }
          }

          &__img {
            padding: 1em;
            z-index: 3;
            transition: .3s;
          }

          &__title {
            position: absolute;
            color: vars.$white;
            width: 10em;
            opacity: 0;
            z-index: 2;
            transform: translateY(1em);
            transition: .3s;
            text-align: center;
          }
        }
      }
    }
  }

  .project_selected {
    border-color: vars.$grey;
  }
}

@media screen and (min-width: 1440px) {
  .portfolio {
    &__menu {
      height: 10em;
      width: 40em;

      &__list {
        width: 50em;

        &__item {
          height: 10em;

          &__img {
            height: 80px;
          }

          &:hover {
            .portfolio__menu__list__item__img {
              transform: translateY(-1em);
            }

            .portfolio__menu__list__item__title {
              opacity: 1;
              transform: translateY(2em);
            }
          }

          &__title {
            font-size: 1em;
            transform: translateY(1em);
          }
        }
      }
    }
  }

  hr {
    opacity: 0;
    animation: apparition 1s 1s ease-out forwards;
  }
}

@media screen and (min-width: 1920px) {
  .portfolio {
    &__menu {
      width: 50em;
    }
  }
}