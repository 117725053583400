@use '../../styles/vars';

.contact {
  width: 100vw;
  min-height: 100vh;
  margin-bottom: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    margin-top: 2em;
    color: vars.$white;
    font-weight: bold;
    font-size: 2em;
    opacity: 0;
    transform: translateY(-20px);
    animation: apparition 1s ease-out forwards;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__form {
      margin-top: 4em;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90vw;
      opacity: 0;
      transform: translateX(-20px);
      animation: apparition 1s ease-out forwards;

      &__input {
        padding: 1em;
        font-size: 0.7em;
        text-align: center;
        background-color: vars.$white;
        border-radius: 50px;
        color: vars.$black;
        width: 80vw;
        max-width: 250px;
        font-family: inherit;
        border: 2px solid transparent;

        &:hover {
          border-color: #aa7ae4;
        }

        &:focus {
          outline: none !important;
          border-color: vars.$mainColor;
        }
      }

      &__area {
        margin-top: 2em;
        padding: 2em;
        height: 15em;
        border-radius: 20px;
        font-size: 0.7em;
        width: 250px;
        max-width: 80vw;
        min-width: 150px;
        min-height: 150px;
        max-height: 60vh;
        color: vars.$black;
        background-color: vars.$white;
        font-family: inherit;
        border: 2px solid transparent;

        &:hover {
          border-color: #aa7ae4;
        }

        &:focus {
          outline: none !important;
          border-color: vars.$mainColor;
        }
      }

      &__submit {
        margin-top: 1em;
        border: none;
        display: flex;
        align-items: center;
        background: transparent;
        background-color: vars.$mainColor;
        border-radius: 20px;
        padding: 0.7em;
        transition: .2s;

        &__icon {
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          &__img {
            height: 100%;
          }
        }

        &__label {
          color: vars.$white;
          margin: 0 1em;
          font-size: 0.8em;
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }

    &__success {
      padding: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 3em;
      opacity: 0;
      transform: translateX(-20px);
      animation: apparition 1s ease-out forwards;

      &__icon {
        height: 30px;
        width: 30px;
        margin-bottom: 1em;
        display: flex;
        align-items: center;
        justify-content: center;

        &__img {
          height: 100%;
        }
      }

      &__message {
        color: vars.$white;
        font-weight: bold;
      }
    }

    &__infos {
      margin-top: 3em;
      opacity: 0;
      transform: translateX(20px);
      animation: apparition 1s ease-out forwards;

      &__list {
        display: flex;
        flex-direction: column;

        &__item {
          margin-top: 2em;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__icon {
            background-color: vars.$mainColor;
            padding: 0.7em;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            &__img {
              height: 20px;
            }
          }

          &__label {
            color: vars.$grey;
            margin-top: 1em;
            font-size: 0.7em;
          }

          &__span {
            position: absolute;
            opacity: 0;
          }

          &__hover {
            position: absolute;
            opacity: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .contact {
    margin-bottom: 0;

    &__title {
      margin-top: 0;
      font-size: 4em;
      margin-bottom: 2em;
      margin-left: 5em;
      padding-top: 1em;
    }

    &__container {
      width: auto;
      margin-left: 20em;

      &__form {
        margin-top: 0;
        width: auto;
        align-items: flex-start;

        &__input {
          width: 20em;
          max-width: 20em;
          text-align: start;
          font-size: 0.8em;
          padding-left: 2em;
          border: 3px solid transparent;
        }
        
        &__area {
          width: 25em;
          height: 15em;
          max-width: 50vw;
          min-width: 15em;
          max-height: 30vh;
          min-height: 10em;
          font-size: 0.8em;
          padding-left: 2em;
          border: 3px solid transparent;
        }

        &__submit {
          margin-top: 2em;
          background-color: transparent;
          border-radius: none;
          padding: 0;

          &__icon {
            height: 50px;
            width: 50px;
            background-color: vars.$mainColor;
            border-radius: 50%;
            padding: 1em;
            z-index: 2;
          }
          
          &__label {
            opacity: 0;
            transform: translateX(-3em);
            opacity: 0;
            z-index: 1;
            transition: .3s;
            margin: 0;
            font-size: 1em;
            background-color: vars.$mainColor;
            padding: 1.3em 2.5em;
            height: 50px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
          }

          &:hover {
            .contact__container__form__submit__label {
              opacity: 1;
              transform: translateX(-2em);
            }
          }
        }
      }

      &__success {
        margin-top: 0;
        padding: 2em;
        border-radius: 50px;
        border: 2px solid vars.$mainColor;
        flex-direction: row;

        &__icon {
          margin-right: 1em;
          margin-bottom: 0;
        }
      }

      &__infos {

        &__list {
          justify-content: space-evenly;

          &__item {
            flex-direction: row;
            margin: 1em;
            position: relative;

            &.mail {
              cursor: copy;
            }

            &__icon {
              height: 60px;
              width: 60px;
              padding: 1em;

              &__img {
                height: 30px;
              }
            }

            &__label {
              color: vars.$white;
              margin-top: 0;
              margin-left: 1em;
              font-size: 0.8em;
            }

            &__span {
              color: vars.$grey;
              font-size: 0.7em;
              transform: translate(75%, 0);
              transition: .3s;
            }

            &__hover {
              color: vars.$grey;
              font-size: 0.7em;
              transform: translate(8em, 0);
              width: 25em;
              transition: .3s;
            }

            &:hover {
              .contact__container__infos__list__item__hover {
                transform: translate(8em, 2.5em);
                opacity: 1;
              }
            }

            &.copied {
              .contact__container__infos__list__item__span {
                opacity: 1;
                transform: translate(75%, 2.5em);
              }

              .contact__container__infos__list__item__hover {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  @keyframes success {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 1440px) {
  .contact {
    margin-bottom: 0;

    &__container {
      flex-direction: row;
      width: auto;

      &__form {
        margin-top: 0;
        width: auto;
        align-items: flex-start;

        &__input {
          width: 30em;
          text-align: start;
          font-size: 1em;
          padding-left: 2em;
        }
        
        &__area {
          width: 37em;
          max-width: 50vw;
          max-height: 60vh;
          min-width: 25em;
          min-height: 15em;
          height: 20em;
          font-size: 1em;
          padding-left: 2em;
          border-radius: 50px;
        }

        &__submit {
          margin-top: 2em;
          background-color: transparent;
          border-radius: none;
          padding: 0;

          &__icon {
            height: 50px;
            width: 50px;
          }
          
          &__label {
            opacity: 0;
            transform: translateX(-3em);
            opacity: 0;
            z-index: 1;
            transition: .3s;
            margin: 0;
            font-size: 1em;
            background-color: vars.$mainColor;
            padding: 1.3em 2.5em;
            height: 50px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
          }

          &:hover {
            .contact__container__form__submit__label {
              opacity: 1;
              transform: translateX(-2em);
            }
          }
        }
      }

      &__infos {
        margin-top: 0;
        margin-left: 5em;

        &__list {
          justify-content: space-evenly;

          &__item {
            flex-direction: row;
            margin: 1em;

            &__icon {
              height: 70px;
              width: 70px;

              &__img {
                height: 35px;
              }
            }

            &__label {
              color: vars.$white;
              margin-top: 0;
              margin-left: 1em;
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}