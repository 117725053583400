@use '../../styles/vars';

.easter-egg {
  &__button {
    padding: 1em;
    z-index: 12;
    width: 10em;
    margin-top: 2em;
    transition: .2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
      background-color: vars.$mainColor;
      padding: 1em;
      border-radius: 50%;
      z-index: 12;
      border: 2px solid vars.$mainColor;
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      &__img {
        height: 100%;
      }
    }

    &__label {
      opacity: 0;
      width: 10em;
      transition: .3s;
      z-index: 13;
      transform: translateY(-1em);
      z-index: 1;
    }

    &:hover {
      .easter-egg__button__label {
        opacity: 1;
        transform: translateY(1em);
      }

      .easter-egg__button__icon {
        border-color: vars.$white;
      }
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

@media screen and (max-width: 850px) {
  .easter-egg {

    &__title {
      font-size: 0.8em;
    }

    &__button {
      &__icon {
        height: 50px;
        width: 50px;
        padding: 0.7em;
      }

      &__label {
        transform: none;
        opacity: 1;
        margin-top: 1em;
        font-size: 0.7em;
      }

      &:hover {
        .easter-egg__button__label {
          transform: none;
        }
      }
    }
  }
}



// Copy pasta from the web

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-$width / 2 + px
               random($height)-$height / 1.2 + px
               hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}

@mixin keyframes ($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay ($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration ($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation ($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform ($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}

@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
}

@media screen and (max-width: 850px) {
  .pyro {
    display: none;
  }
}