@use '../../styles/vars';

.behind {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 25;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    margin-left: 10em;
    max-width: 90vw;
    background: vars.$darkGrey;
    color: vars.$white;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4em 4em 2em 4em;
    border-radius: 20px;
    z-index: 30;
    position: relative;
    opacity: 0;
    transform: translateY(30px);
    animation: apparition 1s ease-out forwards;

    &__quit {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0%;
      right: 0%;
      width: 40px;
      height: 40px;
      border: none;
      background: transparent;
      border-radius: 50%;
      padding: 0.8em;
      transition: transform .2s;
      cursor: none !important;
      background: vars.$mainColor;
      border: 2px solid vars.$mainColor;
      transform: translate(50%, -50%);
      z-index: 2;


      &__icon {
        height: 100%;
      }

      &__label {
        color: vars.$white;
        position: absolute;
        opacity: 0;
        z-index: 1;
        transform: translateY(0.5em);
        transition: .3s;
      }

      &:hover {
        border-color: vars.$white;

        .modal__container__quit__label {
          opacity: 1;
          transform: translateY(2.5em);
        }
      }

      &:active {
        transform: translate(50%, -50%) scale(0.8);
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .modal {
    &__container {
      margin-left: 0;
      padding: 4em 2em;

      &__quit {
        transform: none;
        background: transparent;
        border-color: transparent;
        cursor: pointer !important;

        &__label {
          display: none;
        }

        &:hover {
          border-color: transparent;
        }

        &:active {
          transform: scale(0.8);
        }
      }
    }
  }
}