@use '../../../styles/vars';

.project {
  width: 100vw;
  margin-top: 4em;
  opacity: 0;
  animation: apparition 1s 1s ease-out forwards;

  &__header {
    display: flex;
    align-items: center;
    width: 100vw;

    &__logo {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1em;

      &__img {
        height: 100%;
      }
    }

    &__title {
      color: vars.$white;
      font-weight: bold;
      font-size: 1.5em;
    }
  }

  &__infos {
    width: 100vw;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__item {
        width: 90vw;

        &__title {
          color: vars.$white;
          font-weight: bold;
        }

        &__text {
          margin-top: 0.5em;
          margin-bottom: 2em;
          color: vars.$grey;
        }

        &__techno {
          margin: 0.5em 0 2em;
          display: flex;
          justify-content: space-evenly;
          width: 90vw;
          flex-wrap: wrap;

          &__langages {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 0.5em 1em;

            &__img {
              height: 25px;
              width: 25px;

              &__icon {
                height: 100%;
              }
            }

            &__label {
              margin-top: 0.7em;
              color: vars.$grey;
              font-size: 0.7em;
              text-align: center;
            }
          }
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-evenly;
      margin: 1em 0 0;

      &__item__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: .2s;

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          background-color: vars.$mainColor;
          padding: 0.7em;
          border-radius: 50%;

          &__img {
            height: 100%;
          }
        }

        &__label {
          font-size: 0.8em;
          margin-top: 1em;
          color: vars.$white;
        }

        &:active {
          transform: scale(0.8);
        }
      }
    }
  }
}

hr {
  width: 20vw;
}

@media screen and (min-width: 850px) {
  .project {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
      width: 40em;
      padding: 5em;

      &__title {
        font-size: 2.5em;
        margin-left: 1em;
      }

      &__logo {
        height: 6em;
      }
    }

    &__infos {
      width: 30em;

      &__list {
        align-items: baseline;

        &__item {
          width: auto;

          &__techno {
            width: 30em;

            &__langages__img {
              height: 40px;
              width: 40px;
            }
          }

          &__text {
            margin-top: 0.1em;
          }
        }
      }

      &__buttons {
        margin-bottom: 5em;

        &__item {
          &__link {
            position: relative;

            &__icon {
              height: 50px;
              width: 50px;
              padding: 0.7em;
              z-index: 2;
              border: 2px solid vars.$mainColor;
            }

            &__label {
              position: absolute;
              text-align: center;
              font-size: 1em;
              z-index: 1;
              opacity: 0;
              transform: translateY(0.5em);
              transition: .3s;
              width: 15em;
            }

            &:hover {
              .project__infos__buttons__item__link__icon {
                border-color: vars.$white;
              }

              .project__infos__buttons__item__link__label {
                opacity: 1;
                transform: translateY(2.5em);
              }
            }
          }
        }
      }
    }
  }
}
