@use '../../styles/vars';

.about {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  &__history {
    width: 90vw;
    max-width: 500px;
    transform: translateX(100px);
    opacity: 0;
    transition: 1s;

    &__1 {
      animation: apparition 1s 0.5s ease-out forwards;
    }

    &__2 {
      animation: apparition 1s 1s ease-out forwards;
    }

    &.slide__from-right {
      transform: translateX(0);
      opacity: 1;
    }

    &__title {
      font-weight: bold;
      color: vars.$white;
      margin: 3em 0 1em 0;
    }

    &__text {
      font-size: 0.8em;
      color: vars.$grey;
      margin-bottom: 1em;

      &_violet {
        color: vars.$mainColor;
        padding-left: 0.3em;
        font-weight: bold;
      }
      
      &_bold {
        font-weight: bold;
      }
    }

    &__images {
      margin-top: 3em;
      margin-bottom: 10em;
      display: flex;
      justify-content: space-evenly;
      width: 90vw;
      max-width: 500px;

      &__item {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__img {
          border-radius: 50%;
          height: 100%;
        }
      }
    }
  }

  &__knowledge {
    width: 90vw;
    max-width: 500px;
    transform: translateX(100px);
    opacity: 0;
    transition: 1s;

    &.slide__from-right {
      transform: translateX(0);
      opacity: 1;
    }

    &__title {
      font-weight: bold;
      color: vars.$white;
      margin: 3em 0 1em 0;
    }

    &__text {
      color: vars.$grey;
      font-size: 0.7em;
      display: flex;
      align-items: center;
      border-left: 2px solid vars.$white;
      padding-bottom: 1.5em;
      text-align: center;

      &:last-child {
        border-color: transparent;
        transform: translate(0, -1px);
      }

      &__icon {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        background-color: vars.$mainColor;
        padding: 0.3em;
        transform: translateX(-1em);
        display: flex;
        align-items: center;
        justify-content: center;

        &__img {
          height: 100%;
        }
      }

      &__year {
        background-color: vars.$darkGrey;
        border-radius: 20px;
        padding: 0.2em 0.8em;
        margin-right: 0.5em;
        font-size: 0.7em;
      }

      &_violet {
        color: vars.$mainColor;
        margin-left: 0.3em;
      }
        
      &_bold {
        font-weight: bold;
        margin-right: 0.3em;
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8em;
    width: auto;

    &__history {
      margin-left: 10em;
      width: 30em;
      max-width: 30em;

      &__title {
        font-size: 1.8em;
        margin-bottom: 1rem;
        margin-top: 2em;
      }

      &__text {
        font-size: 0.9em;
      }

      &__images {
        margin-top: 3em;
        margin-bottom: 10em;
        display: flex;
        justify-content: space-evenly;
        width: 30em;
        max-width: 30em;

        &__item {
          height: 75px;
          width: 75px;
          transition: .3s;

          &:hover {
            transform: scale(3);
          }
        }
      }
    }

    &__knowledge {
      margin-left: 10em;
      width: 30em;
      max-width: 30em;

      &__title {
        font-size: 1.8em;
        margin-bottom: 1.8rem;
      }

      &__text {
        font-size: 0.9em;
        margin-left: 1em;
        padding-bottom: 3em;

        &__icon {
          border-radius: 50%;
          height: 30px;
          width: 30px;
          background-color: vars.$mainColor;
          padding: 0.3em;
          transition: .3s;
          transform: translateX(-1.15em);

          &:hover {
            z-index: 2;
            transform: translateX(-1.15em) scale(2);
          }
        }

        &_violet {
          color: vars.$mainColor;
          margin-left: 0.3em;
        }
        
        &_bold {
          font-weight: bold;
          margin-right: 0.5em;
          margin-left: 0.5em;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .about {
    &__history {
      width: 50em;
      max-width: 50em;

      &__title {
        font-size: 3em;
        margin-bottom: 1.5rem;
      }

      &__text {
        font-size: 1.1em;
      }

      &__images {
        width: 50em;
        max-width: 50em;
        margin-top: 10em;
        margin-bottom: 20em;
        
        &__item {
          height: 150px;

          &:hover {
            transform: scale(3);
          }
        }
      }
    }

    &__knowledge {
      width: 50em;
      max-width: 50em;

      &__title {
        font-size: 3em;
        margin-bottom: 3rem;
      }

      &__text {
        font-size: 1.1em;

        &__icon {
          border-radius: 50%;
          height: 30px;
          background-color: vars.$mainColor;
          padding: 0.3em;
          transition: .3s;
          transform: translateX(-0.9em);
          margin-right: 0.5em;

          &:hover {
            z-index: 2;
            transform: translateX(-0.9em) scale(2);
          }
        }
      }
    }
  }
}