@use '../../styles/vars';

.knowledges {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8em;
  opacity: 0;
  animation: apparition 1s ease-out forwards;

  &__CV {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5vh;
    height: 60px;
    width: 60px;
    transform: translateY(-20px);
    opacity: 0;
    animation: apparition 1s ease-out forwards;

    &__icon {
      height: 100%;
      background-color: vars.$mainColor;
      border-radius: 50%;
      z-index: 2;
      padding: 1em;
      transition: .3s;
    }

    &__label {
      margin-top: 0.8em;
      color: vars.$white;
      font-size: 0.7em;
      width: 200%;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  &__skills {
    width: 90vw;
    background-color: vars.$darkGrey;
    padding: 1em;
    margin-top: 1em;
    border-radius: 10px;

    &__title {
      color: vars.$white;
      font-weight: bold;
      font-size: 1em;
      margin-bottom: 0.5rem;
    }

    &__subtitle {
      color: vars.$grey;
      font-size: 0.6em;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 2em;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0.4em;

        &__icon {
          height: 100%;
          padding: 0.5em;
        }

        &__label {
          color: vars.$white;
          font-size: 0.3rem;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .knowledges {
    overflow-x: hidden;
    
    &__CV {
      margin: 0;
      position: fixed;
      right: 4em;
      bottom: 4em;
      z-index: 30;
      transition: .3s;
      opacity: 0;
      transform: translateY(20px);
      animation: apparition 1s 1s ease-out forwards;

      &__icon {
        padding: 0.7em;
        border: 2px solid vars.$mainColor;
        z-index: 2;
        transition: .3s;
      }

      &__label {
        font-size: 0.7em;
        margin-top: 0;
        position: absolute;
        opacity: 0;
        z-index: 1;
        width: 10em;
        transform: translateY(2em);
        transition: .3s;
      }

      &:hover {
        .knowledges__CV__icon {
          background-color: vars.$mainColor;
          border: 2px solid vars.$white;
        }

        .knowledges__CV__label {
          opacity: 1;
          transform: translateY(4em);
        }
      }
    }

    &__skills {
      width: 40vw;
      margin-top: 5em;
      margin-bottom: 0;
      padding: 2em;
      border-radius: 20px;
      margin-left: 5em;

      &__title {
        font-size: 1.2em;
      }

      &__list__item {
        width: 50px;
        height: 50px;
        margin: 1em;

        &__label {
          font-size: 0.5rem;
          line-height: 120%;
        }
      }
    }
  }

  .left {
    transition: 1s;
    transform: translateX(100vw);

    &.slide__from-right {
      transform: translateX(0);
    }
  }

  .right {
    transition: 1s;
    transform: translateX(-100vw);

    &.slide__from-left {
      transform: translateX(0);
    }
  }
}

@media screen and (min-width: 1300px) {
  .left {
    transform: translateX(-5em);
  }

  .right {
    transform: translateX(5em);
  }
}

@media screen and (min-width: 1920px) {
  .left {
    transform: translateX(-10em);
  }

  .right {
    transform: translateX(10em);
  }

  .knowledges {
    margin: auto;
    padding: 10em;

    &__CV {
      right: 5em;
      bottom: 5em;
      opacity: 0;
      transform: translateY(20px);
      animation: apparition 1s 1s ease-out forwards;

      &__icon {
        height: 80px;
        padding: 1em;
      }

      &__label {
        font-size: 1em;
        margin-top: 0;
        position: absolute;
        opacity: 0;
        z-index: 1;
        width: 10em;
        transform: translateY(1.5em);
        transition: .3s;
      }

      &:hover {
        .knowledges__CV__icon {
          background-color: vars.$mainColor;
        }

        .knowledges__CV__label {
          opacity: 1;
          transform: translateY(3.5em);
        }
      }
    }

    &__skills {
      width: 60em;
      margin-top: 4em;
      margin-bottom: 4em;
      padding: 4em;

      &__title {
        font-size: 2em;
        margin-bottom: 1rem;
      }

      &__subtitle {
        font-size: 1em;
      }

      &__list {
        margin-top: 2em;

        &__item {
          position: relative;
          height: 80px;
          width: 80px;

          &__icon {
            padding: 1em;
            z-index: 2;
          }

          &__label {
            position: absolute;
            font-size: 1em;
            opacity: 0;
            z-index: 1;
            transform: translateY(1em);
            transition: .3s;
            width: 20em;
            line-height: 140%;
          }
          
          &:hover {
            .knowledges__skills__list__item__label {
              opacity: 1;
              transform: translateY(3em);
            }
          }
        }
      }
    }
  }
}