@use 'reset';
@use 'vars';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins';
  background: vars.$black;

  ::selection {
    background-color: vars.$mainColor;
    color: vars.$white;
  }
}

@keyframes apparition {
  100% {
    transform: none;
    opacity: 1;
  }
}

@media screen and (min-width: 850px) {
  body {
    line-height: 100%;
  }

  * {
    cursor: none;
  }
}

@media screen and (min-width: 1920px) {
  body {
    line-height: 130%;
  }
}