@use '../../styles/vars';

.projects {
  margin-top: 2em;
  padding: 1em;
  opacity: 0;
  transform: translateY(-30px);
  animation: apparition 1s 1s ease-out forwards;

  &__title {
    color: vars.$white;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 0.8rem;

    &_violet {
      color: vars.$mainColor;
    }
  }

  &__text {
    color: vars.$grey;
    margin-bottom: 4em;
    font-size: 0.8em;

    &_violet {
      color: vars.$mainColor;
      font-weight: bold;
      margin-left: 0.3em;
    }

    &_bold {
      font-weight: bold;
    }
  }
}

@media screen and (min-width: 850px) {
  .projects {
    margin-top: 5em;
    width: 30em;
    padding: 0 3em;
    border-radius: 20px;
    margin-bottom: 8em;

    &__title {
      color: vars.$white;
      font-size: 1.3em;
      font-weight: bold;
      margin-bottom: 1rem;
      margin-top: 2em;
    }

    &__text {
      margin-bottom: 3em;
    }
  }
}

@media screen and (min-width: 1440px) {
  .projects {
    width: 40em;

    &__title {
      font-size: 1.6em;
    }

    &__text {
      font-size: 0.9em;
    }
  }
}

@media screen and (min-width: 1920px) {
  .projects {
    width: 50em;

    &__title {
      font-size: 2em;
    }

    &__text {
      font-size: 1em;
    }
  }
}