@use '../../styles/vars';

.nav {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 6em;
  display: flex;
  align-items: center;
  z-index: 99;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
  backdrop-filter: blur(2px);
  opacity: 0;
  animation: apparition 1s ease-out forwards;

  &__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    &__button {
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        transition: .2s;

        &__icon {
          height: 100%;
          padding: 0.9em;
          background-color: vars.$mainColor;
          border-radius: 50%;
          border: 2px solid vars.$mainColor;
          transform: translateY(-0.4em);
        }

        &__label {
          font-size: 0.7em;
          margin-top: 0.3em;
          color: vars.$white;
          text-align: center;
          width: 200%;

          &_hidden {
            display: none;
          }
        }

        :active {
          transition: .2s;
          transform: scale(0.9) translateY(-0.4em);
        }
      }
    }
  }
}

.selected {
  .nav__list__button__item__icon {
    border-color: vars.$white;
  }
}

@media screen and (min-width: 850px) {
  .nav {
    background: transparent;
    width: auto;
    height: 100vh;
    bottom: auto;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    backdrop-filter: none;
    transform: translateX(-100px);
    opacity: 0;
    animation: apparition 1s 1s ease-out forwards;

    &__list {
      width: auto;
      flex-direction: column;
      margin-left: 2em;
      opacity: inherit;

      &__button {
        margin: 1.2vh;
        opacity: inherit;

        &__item {
          flex-direction: row;
          height: 55px;
          width: 55px;
          opacity: inherit;

          &__icon {
            transform: translateY(0);
            padding: 1em;
            z-index: 2;
            border: 2px solid vars.$mainColor;
          }

          &__label {
            font-size: 0.9em;
            transform: translateX(-2em);
            transition: .3s;
            opacity: 0;
            z-index: 1;
            text-align: start;
            white-space: nowrap;
          }

          &:hover {
            .nav__list__button__item__icon {
              border-color: vars.$white;
              background-color: vars.$mainHover;
            }

            .nav__list__button__item__label {
              transform: translateX(1em);
              opacity: 1;
            }
          }

          :active {
            transition: .2s;
            transform: scale(0.9) translateY(0);
          }
        }
      }
    }
  }

  .selected {
    .nav__list__button__item__icon {
      transition: box-shadow 1s;
      box-shadow: 0 0 50px vars.$grey;
    }
  }
}

@media screen and (min-width: 1440px) {
  .nav {
    &__list {
      margin-left: 3em;

      &__button {
        margin: 1.5vh;

        &__item {
          &__icon {
            height: 60px;
            padding: 1.1em;
          }

          &__label {
            font-size: 1em;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .nav {
    height: 5em;

    &__list__button__item {
      &__icon {
        transform: translateY(0);
      }

      &__label {
        display: none;
      }

      :active {
        transition: .2s;
        transform: scale(0.9) translateY(0);
      }
    }
  }
}