@use '../../styles/vars';

.socials {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(-30px);
  animation: apparition 1s ease-out forwards;

  &__icon {
    margin-top: 2em;
    border: 2px solid vars.$mainColor;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__img {
      height: 100%;
    }
  }

  &__container {
    display: none;
  }
}

@media screen and (min-width: 850px) {
  .social_0 {
    --i: 0.25;
  }

  .social_1 {
    --i: 1.25;
  }

  .social_2 {
    --i: 2.25;
  }

  .social_3 {
    --i: 3.25;
  }

  .social_4 {
    --i: 4.25;
  }

  .socials {
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1.25s;
    margin-bottom: 8em;

    &__icon {
      display: none;
    }

    &__container {
      display: contents;

      &.opened li {
        visibility: visible;
        transform: rotate(calc(360deg / 5 * var(--i)));
      }

      &.opened span {
        display: none;
      }

      &__toggle {
        position: absolute;
        width: 140px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 30;
        border-radius: 50%;
        cursor: pointer;
        transform: translateX(80px);

        &__img {
          height: 100%;
          border: 3px solid transparent;
          border-radius: 50%;
          z-index: 2;
          border-color: vars.$mainColor;
        }

        &__label {
          color: vars.$grey;
          width: 20em;
          opacity: 0;
          position: absolute;
          transition: .3s;
          z-index: 1;
          transform: translateY(3.5em);
          text-align: center;
        }

        &:hover {
          .socials__container__toggle__label {
            transform: translateY(5.5em);
            opacity: 1;
          }
        }
      }

      &__links {
        position: absolute;
        left: 0;
        list-style: none;
        transform-origin: 180px;
        transition: 0.5s;
        transition-delay: calc(0.1s * var(--i));
        transform: rotate(0deg) translateX(160px);
        visibility: hidden;

        & a {
          display: flex;
          justify-content: center;
          align-items: center;
          transform: rotate(calc(360deg / -5 * var(--i)));
          transition: 0.3s;

          &:active {
            transform: scale(0.8) rotate(calc(360deg / -5 * var(--i)));
          }

          &:hover {
            .socials__container__links__item {
              background-color: vars.$mainHover;
              border-color: vars.$white;
            }

            .socials__container__links__label {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 12;
          background-color: vars.$mainColor;
          width: 60px;
          height: 60px;
          padding: 1em;
          border-radius: 50%;
          border: 2px solid vars.$mainColor;

          &__img {
            height: 100%;
          }
        }

        &__label {
          position: absolute;
          bottom: -2em;
          font-size: 0.8em;
          color: vars.$white;
          z-index: 11;
          opacity: 0;
          transform: translateY(-2em);
          transition: 0.3s;
        }
      }
    }
  }
}